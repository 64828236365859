/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import document from 'global/document';
import Spinner from './Spinner';

import './App.scss';
import {
  overrideRedirect,
  analytics,
  handleRegistrationButtonClick,
  usePoolingUserLogin,
  useDepositRedirect,
  useOverwriteFooterValues,
  useLoginTracking,
  useLoginTrackingUK,
  useHeaderHeight,
  useQueryParams,
} from '../utils';
import { useServerData } from '../state/serverDataContext';
import {
  isItalyLocale,
  isMaltaLocale,
  isSloveniaLocale,
  isLuxembourgLocale,
  isMauritiusLocale,
  isNewZealandLocale,
  isIcelandLocale,
  isFinlandLocale,
  isSpainLocale,
} from '../utils/locales';

const Banner = Loadable({
  loader: () => import(/* webpackChunkName: 'Banner' */ './Banner'),
  loading: () => <div>Loading...</div>,
});

const LoadTermsConditions = Loadable({
  loader: () => import(/* webpackChunkName: 'TermsConditions' */ './TermsConditions'),
  loading: () => <div>Loading...</div>,
});

const LoadCookieBar = Loadable({
  loader: () => import(/* webpackChunkName: 'CookieBar' */ './CookieBar'),
  loading: () => <div>Loading...</div>,
});

const LoadTopBets = Loadable({
  loader: () => import(/* webpackChunkName: 'TopBets' */ './TopBets'),
  loading: () => <div>Loading...</div>,
});

const TeaserContainer = Loadable({
  loader: () => import(/* webpackChunkName: 'TeaserContainer' */ './TeaserContainer'),
  loading: () => <div>Loading...</div>,
});

const LoadFooter = Loadable({
  loader: () => import(/* webpackChunkName: 'Footer' */ './Footer'),
  loading: () => <div>Loading...</div>,
});

const MoreVerticals = Loadable({
  loader: () => import(/* webpackChunkName: 'MoreVerticals' */ './MoreVerticals'),
  loading: () => <div>Loading...</div>,
});

const App = () => {
  const { redirectUrl, environment, noLogin, domain, locale, shouldRedirect, serverError, isMobile } = useServerData(
    data => ({
      redirectUrl: data.pageData?.pageSettings?.redirect.replace(
        '{env}',
        data.config.environment === 'prod' ? '' : '-pp1'
      ),
      locale: data.locale,
      domain: data.domain,
      noLogin: data.nologin || false,
      serverError: data.serverError || false,
      shouldRedirect: data.shouldRedirect || false,
      environment: data.config?.environment || 'prod',
      isMobile: data.isMobile,
    })
  );

  const [loader, showLoader] = useState(false);
  const onLoadingChange = status => showLoader(status);

  const isItaly = isItalyLocale(locale);
  const isSpain = isSpainLocale(locale);
  const isMalta = isMaltaLocale(locale);
  const isLuxembourg = isLuxembourgLocale(locale);
  const isSlovenia = isSloveniaLocale(locale);
  const isMauritius = isMauritiusLocale(locale);
  const isNewZealand = isNewZealandLocale(locale);
  const isIceland = isIcelandLocale(locale);
  const isFinland = isFinlandLocale(locale);
  const headerHeight = useHeaderHeight();

  usePoolingUserLogin(noLogin, onLoadingChange, redirectUrl);
  useDepositRedirect(locale, redirectUrl);
  useOverwriteFooterValues();
  useLoginTracking(locale, environment);
  useQueryParams('action');

  useEffect(() => {
    overrideRedirect(locale);
    analytics(environment, shouldRedirect, locale);
    useLoginTrackingUK(locale, environment, isMobile);

    const headerActionArea = document.querySelector('.sb-action-area');
    if (headerActionArea && (isItaly || isFinland)) {
      const handleClick = handleRegistrationButtonClick(locale);
      headerActionArea.addEventListener('click', handleClick);
      return () => document.removeEventListener('click', handleClick);
    }
  }, [environment]);

  useEffect(() => {
    if (serverError) {
      console.warn('An error occurred when fetching ContentStack data.');
    }
  }, [serverError]);

  let topBetsElement = null;
  let footerElement = null;

  let slider = <Banner />;

  if (domain === 'at') {
    footerElement = <LoadFooter />;
    topBetsElement = <LoadTopBets />;
  }

  const termsAndConditionsIsVisible =
    isItaly || isMalta || isNewZealand || isIceland || isSlovenia || isLuxembourg || isMauritius;

  const cookieBannerIsNotVisible = isItaly || isSpain; // for Italy and Spain there is cookie banner loaded by utag.js

  return (
    <div className="app" style={{ paddingTop: `${headerHeight}px` }}>
      {loader && <Spinner />}
      <LoadCookieBar environment={environment} cookieBannerIsNotVisible={cookieBannerIsNotVisible} />
      <div className="main" data-testid="inter-app">
        {slider}
        {termsAndConditionsIsVisible ? <LoadTermsConditions /> : null}
        {topBetsElement}
        <MoreVerticals />
        <TeaserContainer />
      </div>
      {footerElement}
    </div>
  );
};

export default App;
